/* eslint-disable no-inline-comments */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollMagic from 'scrollmagic';
import * as Styled from './anchoredScrollText.styles';
import {
  calculatePadding,
  CATCH_UP_SCROLL,
  STICK_DURATION,
  HEIGHT,
  HEADER_ADJUSTMENT,
} from './anchoredScrollText.styles';
import MendedHeart from '../assets/images/mendedHeartSvg';
import {
  COLOR_CHANGEABLE_DIVIDER_COLOR_LABEL,
  COLORS,
  ImageCredit,
} from '../styles/homePageStyles';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';
import Link from '../link/link.component';
import ColorChangeableDivider from '../colorChangeableDivider/colorChangeableDivider.component';

const AnchoredScrollTextStatic = ({ statements, ctaLink, bgImage, children }) => {
  // STATE
  // the transition variable allows us to add some styling that reduces opacity when the user
  // is resizing or when we are otherwise waiting for the anchored scroll text container
  // to "find" its position, which sometimes takes a moment
  const [isTransitioning, setIsTransitioning] = useState(false);
  // used to determine whether we consider screen to be mobile, and to trigger re-run of ScrollMagic scenes on resize
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // used for styling and for determining when the hijacking pin should trigger
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [delayContentVisibility, setDelayContentVisibility] = useState(false);
  const [delayedContentReady, setDelayedContentReady] = useState(false);

  const [scrollMagicProgress, setProgress] = useState(0);
  const [componentTop, setComponentTop] = useState(0);
  // kind of a hack — we run a bunch of functions on load, multiple times, to find the correct positioning
  // etc. for the scroll hijack component. after a small delay, we want to stop running those functions.
  const [stopUpdates, setStopUpdates] = useState(false);
  // another hack — this state is set after a 0ms timeout. it is part of the dependency array that triggers
  // all of the ScrollMagic scenes to be run. without this impercetible delay, we were running into issues where
  // the scenes weren't getting triggered on certain back button actions. weird!
  const [placeHijack, triggerPlacement] = useState(false);
  // if the placeholder element doesn't exist, we shouldn't display anything!
  const [placeholderExists, setDoesPlaceholderExist] = useState(
    !!document.getElementById('anchored-scroll-text-placeholder'),
  );

  // OTHER CONSTS, VALIDATIONS, ETC.
  // consts, etc.
  const { url, urlText } = ctaLink;
  const controller = new ScrollMagic.Controller();
  const isMobileScreen = windowWidth < 768;

  // validate the statements array
  if (!statements || statements.length < 3 || statements.length > 4) {
    // eslint-disable-next-line no-console
    console.error('The statements array should have a length of 3 or 4.');
    return null;
  }

  // HELPER FUNCTIONS
  // helper function to get the y position where the anchored scroll text container should be placed on load/refresh/resize
  const getComponentTop = () => {
    const placeholder = document.getElementById('anchored-scroll-text-placeholder');
    // adjust for scroll position, since getBoundingClientRect().top is relative to the viewport
    const position = placeholder ? placeholder.getBoundingClientRect().top + window.scrollY : 0;
    return position;
  };

  // helper function used when component is resized or re-rendered
  const handle = () => {
    const position = getComponentTop();
    // if the user has already started scrolling into the hijacked area, we need to
    // adjust for the scrolling that has been hijacked, which scrollY does not account for.
    // we do this here instead of in getComponentTop b/c getComponentTop is also used
    // to calculate the startOffset value for the ScrollMagic scenes.
    const top =
      position - (CATCH_UP_SCROLL * (statements.length - 1) + STICK_DURATION) * scrollMagicProgress;
    setComponentTop(top);
  };

  const handleResize = () => {
    let timeout;
    clearTimeout(timeout);
    // reset these values so that we can calculate various styles and positions appropriately
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    // change opacity while things are being yanked around
    setIsTransitioning(true);
    // don't calculate resize right away — waiting a moment gives us more accurate placement for the hijacked component
    requestAnimationFrame(handle);
    // hack to force the hijacked component to display properly on resize
    setTimeout(() => {
      if (window.scrollY > 1) {
        window.scrollBy(0, 1);
      } else {
        window.scrollTo(0, 0);
      }
    }, 150);
    // full opacity again
    timeout = setTimeout(() => {
      setIsTransitioning(false);
    }, 1000);
  };

  // EFFECTS

  // this one essentially runs only on load. `scrollMagicProgress` triggers it, but we stop paying attention
  // to that value after `stopUpdates` gets set to true. `statements` probably won't change unless you
  // manually update your cookies to impersonate a different user.
  // Add small delay on navigating back to prevent content from flashing briefly before anchored scroll text is in place
  useEffect(() => {
    if (document.readyState === 'complete') {
      setDelayContentVisibility(true);
      setTimeout(() => setDelayedContentReady(true), 1);
    }
  }, []);

  useEffect(() => {
    if (!stopUpdates) {
      requestAnimationFrame(handle);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollMagicProgress, statements.length]);

  // the placeholder might disappear on resize so that we can show different content at different screen sizes.
  useEffect(() => {
    setDoesPlaceholderExist(!!document.getElementById('anchored-scroll-text-placeholder'));
  }, [document]);

  // empty dependency array = runs only on load
  // this one doesn't actually do any of the positioning or fancy stuff, it just runs some
  // helpers that only need to be run on load.
  useEffect(() => {
    let timer1, timer2, timer3;

    // change opacity while everything is rendering
    setIsTransitioning(true);

    // reset opacity after a moment
    timer1 = setTimeout(() => setIsTransitioning(false), 700);
    // stop running the functions that find the position of the anchored scroll text container
    timer2 = setTimeout(() => setStopUpdates(true), 1000);
    // hack to get the ScrollMagic scenes to run properly on back button actions
    timer3 = setTimeout(() => triggerPlacement(true), 0);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  // scroll hijacking logic. re-runs on load (`placeHijack` is load with a 0ms delay) and if the number of statements
  // changes, which is unlikely to happen unless you manually update your cookies to impersonate a different user.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (placeholderExists) {
      const scenes = {};
      const calculateOpacity = (progress, divisor) => {
        return Math.min(Math.max(progress / divisor, 0), 1);
      };

      // control opacity and fuzziness of statement components as they scroll
      const createOpacityScene = (elementId, offset, index) => {
        const firstEl = index === 0;
        const lastEl = index === statements.length - 1;
        const scene = new ScrollMagic.Scene({
          offset: offset - HEIGHT * 4,
          duration: STICK_DURATION + HEIGHT * 8,
        })
          .on('progress', event => {
            const element = document.getElementById(elementId);
            const progress = event.progress;
            let opacity;
            let blurAmount;

            if (progress < 0.2) {
              // transition from 0 to 1 before the sticky position
              if (!firstEl) {
                opacity = calculateOpacity(progress, 0.2);
                blurAmount = `${(1 - opacity) * 2}px`;
              }
            } else if (progress > 0.4) {
              // transition from 1 to 0 after the sticky duration
              if (!lastEl) {
                if (isMobileScreen && progress > 0.7) {
                  opacity = calculateOpacity(1 - progress, 0.99);
                } else {
                  opacity = calculateOpacity(1 - progress, 0.6);
                  blurAmount = `${(1 - opacity) * 5}px`;
                }
              }
            } else {
              // fully opaque during the sticky duration
              opacity = 1;
              blurAmount = '0px';
            }

            element.style.opacity = opacity;
            element.style.filter = `blur(${blurAmount})`;
          })

          .addTo(controller);

        return scene;
      };

      // sadly, we need to give ScrollMagic an actual pixel start value instead of just e.g. using an intersection observer
      const desktopPaddingPx = windowHeight / 2;
      const paddingPx = calculatePadding();
      // getComponentTop finds top of background image, minus height of header bar
      // desktopPaddingPx is half of the screen height
      // "displacement" is the height of the header bar — subtract it once to adjust for getComponentTop and again because it's a nice adjustment
      // add back the height of the padding above the text in the background image so that the text itself is centered in the screen when hijack begins
      // 5px just because. (Makes the text exactly vertically centered, with equal px distance above and below to top/bottom of screen.)
      const startOffset =
        getComponentTop() - (desktopPaddingPx - HEADER_ADJUSTMENT) + paddingPx + 5;

      const allOtherContentScene = new ScrollMagic.Scene({
        offset: startOffset,
        duration: CATCH_UP_SCROLL * (statements.length - 1) + STICK_DURATION,
      })
        .setPin('#all-other-content')
        .addTo(controller);

      const backgroundContainerScene = new ScrollMagic.Scene({
        offset: startOffset,
        duration: CATCH_UP_SCROLL * (statements.length - 1) + STICK_DURATION,
      })
        .on('progress', event => {
          setProgress(event.progress);
        })
        .setPin('#background-container', { pushFollowers: false })
        .addTo(controller);

      // parallax the background image
      const backgroundImageScene = new ScrollMagic.Scene({
        offset: startOffset,
        duration: CATCH_UP_SCROLL * (statements.length - 1) + STICK_DURATION,
      })
        .on('progress', event => {
          const background = document.getElementById('background-image');
          const parallaxOffset = event.progress * -80;
          background.style.transform = `translateY(${parallaxOffset}px)`;
        })
        .addTo(controller);

      const anchorScene = new ScrollMagic.Scene({
        offset: startOffset,
        duration: CATCH_UP_SCROLL * (statements.length - 1) + STICK_DURATION,
      })
        .setPin('#anchor-statement')
        .addTo(controller);

      statements.forEach((_statement, index) => {
        const offset = CATCH_UP_SCROLL * index + startOffset;

        scenes[`scene${index + 1}`] = new ScrollMagic.Scene({
          offset,
          duration: STICK_DURATION,
        })
          .setPin(`#scroll-statement-${index + 1}`)
          .addTo(controller);

        createOpacityScene(`scroll-statement-${index + 1}`, offset, index);
      });

      return () => {
        controller.destroy(true);
        allOtherContentScene.destroy();
        backgroundContainerScene.destroy();
        backgroundImageScene.destroy();
        anchorScene.destroy();
        statements.forEach((_, idx) => {
          scenes[`scene${idx + 1}`].destroy();
        });
      };
    }
  }, [placeHijack, windowWidth, windowHeight]);

  // LAST BUT NOT LEAST... JSX!
  return (
    <React.Fragment>
      <Styled.AllOtherContent
        id="all-other-content"
        ready={delayContentVisibility ? delayedContentReady : true}
      >
        {children}
      </Styled.AllOtherContent>
      {placeholderExists && (
        <Styled.AnchoredScrollTextContainer
          id="scroll-text-container"
          componentTop={componentTop}
          isTransitioning={isTransitioning}
        >
          <PlatformDetector>
            {({ isMobile, isTabletPortrait }) => (
              <React.Fragment>
                <Styled.BackgroundContainer
                  id="background-container"
                  windowHeight={windowHeight}
                  componentTop={componentTop}
                >
                  <Styled.BackgroundImage
                    id="background-image"
                    bgImage={bgImage}
                    isMobile={isMobile}
                  />
                  <ImageCredit>Photo: Andrew Skowron / WeAnimals Media</ImageCredit>
                  <ColorChangeableDivider color={COLOR_CHANGEABLE_DIVIDER_COLOR_LABEL.GREEN} />
                </Styled.BackgroundContainer>
                <Styled.TextContainer windowHeight={windowHeight}>
                  <React.Fragment>
                    {isMobile || isTabletPortrait ? (
                      <Styled.AnchorText
                        id="anchor-statement"
                        numberOfStatements={statements.length}
                      >
                        <MendedHeart
                          colorHex={COLORS.BRIGHT_RED}
                          heightAndWidth={isMobile ? 40 : 33}
                        />
                        <div>BECAUSE</div>
                      </Styled.AnchorText>
                    ) : (
                      <Styled.AnchorText
                        id="anchor-statement"
                        numberOfStatements={statements.length}
                      >
                        <span id="anchored-because">BECAUSE</span>
                        <MendedHeart
                          colorHex={COLORS.BRIGHT_RED}
                          heightAndWidth={isMobile ? 40 : 33}
                        />
                      </Styled.AnchorText>
                    )}
                  </React.Fragment>
                  {statements.map((statement, index) => {
                    return (
                      <Styled.ScrollText
                        key={`${index}-${statements.length}`}
                        id={`scroll-statement-${index + 1}`}
                        forceOpacity={scrollMagicProgress > 0.75 && index === statements.length - 1}
                      >
                        <em>{statement}</em>

                        {index === statements.length - 1 && (
                          <React.Fragment>
                            <Link url={url} text={urlText} tabIndex={-1} />
                          </React.Fragment>
                        )}
                      </Styled.ScrollText>
                    );
                  })}
                </Styled.TextContainer>
              </React.Fragment>
            )}
          </PlatformDetector>
        </Styled.AnchoredScrollTextContainer>
      )}
    </React.Fragment>
  );
};

AnchoredScrollTextStatic.propTypes = {
  statements: PropTypes.arrayOf(PropTypes.string).isRequired,
  ctaLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    urlText: PropTypes.string.isRequired,
  }).isRequired,
  bgImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AnchoredScrollTextStatic;
