import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DonationBox,
  TYPES,
  DEFAULT_MONTHLY_BUTTON_TEXT,
  DEFAULT_ONE_TIME_BUTTON_TEXT,
} from '../donationBox';
import { Image } from '../image';
import {
  FundraisingSection,
  OuterContainer,
  ImageContainer,
  HeaderContainer,
  CountdownContainer,
  InnerContainer,
  Content,
  HeaderSection,
  LowerContent,
  ImpactText,
  BadgeContainer,
  BadgeImage,
  MobilePadding,
} from './fundraisingHero.styles';
import { Markdown } from '../markdown';
import { Countdown } from '../countdown';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';
import { BUTTON_COLOR_LABEL } from '../styles/homePageStyles';
import { FormattedA } from '../link';

export const FundraisingHero = ({
  heroDesktop,
  heroMobile,
  headline,
  description,
  givingValues,
  preselectedMonthly,
  preselectedOneTime,
  hasCountdown,
  countdownEndDate,
  impactTextMonthly,
  impactTextOneTime,
  buttonTextMonthly,
  buttonTextOneTime,
  buttonUrl,
  badges,
  colorTheme,
  defaultFrequency,
}) => {
  const [activeValue, handleActiveValue] = useState();
  const [isMonthlyDonation, setIsMonthlyDonation] = useState(defaultFrequency !== 'One-Time');

  const valuesObject = {
    monthlyValues: [
      givingValues.firstMonthly,
      givingValues.secondMonthly,
      givingValues.thirdMonthly,
    ],
    oneTimeValues: [
      givingValues.firstOneTime,
      givingValues.secondOneTime,
      givingValues.thirdOneTime,
    ],
  };
  const boldItalicHeadline = headline.startsWith('##') ? headline : `## ${headline}`;
  const endDate = countdownEndDate ? new Date(countdownEndDate) : null;
  const currentDate = new Date();

  const renderBadges = () => {
    if (!badges) {
      return null;
    }

    return badges.map(b => (
      <FormattedA href={b.url.url}>
        <BadgeImage src={b.image.imageFile} alt={b.imageAlt} />
      </FormattedA>
    ));
  };

  const getDynamicHero = isMobile => {
    if (isMobile) {
      return heroMobile.getIn(['fields', 'file', 'url']);
    }
    return heroDesktop.getIn(['fields', 'file', 'url']);
  };

  const getAlt = isMobile => {
    if (isMobile) {
      return heroMobile.getIn(['fields', 'title']);
    }
    return heroDesktop.getIn(['fields', 'title']);
  };

  // Verify that countdown is enabled and the end date is in the future
  const isCountdownValid = () => {
    return hasCountdown &&
           countdownEndDate &&
           endDate > currentDate;
  };

  return (
    <FundraisingSection countdown={isCountdownValid()}>
      <PlatformDetector>
        {({ isMobile }) => (
          <OuterContainer>
            <ImageContainer>
              <Image src={getDynamicHero(isMobile)} alt={getAlt(isMobile)} />
            </ImageContainer>
            <InnerContainer>
              <Content>
                <HeaderContainer>
                  {isCountdownValid() && (
                    <CountdownContainer>
                      <Countdown date={endDate} labelAlign="center" size="medium" />
                    </CountdownContainer>
                  )}
                  <HeaderSection theme="dark" colorTheme={colorTheme}>
                    <Markdown>{boldItalicHeadline}</Markdown>
                  </HeaderSection>
                </HeaderContainer>
                <DonationBox
                  values={valuesObject}
                  description={description}
                  theme={colorTheme}
                  type={TYPES.TABS}
                  preselectedMonthly={preselectedMonthly - 1}
                  preselectedOneTime={preselectedOneTime - 1}
                  buttonTextMonthly={buttonTextMonthly}
                  buttonTextOneTime={buttonTextOneTime}
                  buttonUrl={buttonUrl}
                  isMonthlyDefault={isMonthlyDonation}
                  handleActiveValue={handleActiveValue}
                  handleFrequencyChange={setIsMonthlyDonation}
                ></DonationBox>
                <LowerContent>
                  <ImpactText theme={colorTheme}>
                    <Markdown activeValue={activeValue} colorTheme={colorTheme}>
                      {isMonthlyDonation ? impactTextMonthly : impactTextOneTime}
                    </Markdown>
                  </ImpactText>
                  <BadgeContainer>{renderBadges()}</BadgeContainer>
                  {isMobile && <MobilePadding />}
                </LowerContent>
              </Content>
            </InnerContainer>
          </OuterContainer>
        )}
      </PlatformDetector>
    </FundraisingSection>
  );
};

FundraisingHero.propTypes = {
  heroDesktop: PropTypes.object.isRequired,
  heroMobile: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string,
  givingValues: PropTypes.shape({
    firstMonthly: PropTypes.number.isRequired,
    secondMonthly: PropTypes.number.isRequired,
    thirdMonthly: PropTypes.number.isRequired,
    firstOneTime: PropTypes.number.isRequired,
    secondOneTime: PropTypes.number.isRequired,
    thirdOneTime: PropTypes.number.isRequired,
  }).isRequired,
  preselectedMonthly: PropTypes.oneOf([1, 2, 3]),
  preselectedOneTime: PropTypes.oneOf([1, 2, 3]),
  hasCountdown: PropTypes.bool,
  countdownEndDate: PropTypes.string,
  impactTextMonthly: PropTypes.string.isRequired,
  impactTextOneTime: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorTheme: PropTypes.oneOf(Object.values(BUTTON_COLOR_LABEL)),
  buttonTextMonthly: PropTypes.string,
  buttonTextOneTime: PropTypes.string,
  buttonUrl: PropTypes.string,
  defaultFrequency: PropTypes.oneOf(['Monthly', 'One-Time']),
};

FundraisingHero.defaultProps = {
  hasCountdown: false,
  countdownEndDate: null,
  preselectedMonthly: 2,
  preselectedOneTime: 2,
  badges: [],
  colorTheme: 'yellow',
  buttonTextMonthly: DEFAULT_MONTHLY_BUTTON_TEXT,
  buttonTextOneTime: DEFAULT_ONE_TIME_BUTTON_TEXT,
  defaultFrequency: 'Monthly',
};

export default FundraisingHero;
