import styled from 'styled-components';
import {
  FONTS,
  COLORS,
  TEXT_COLOR,
  Section,
  BUTTON_COLOR_LABEL,
} from '../styles/homePageStyles';
import { Button as ButtonComponent } from '../button/button.styles';
import TickIconSrc from '../../../images/icon-tick-black.svg';
import TabBackgroundActive from '../assets/images/fr-hero-tab-selected.gif';
import TabBackgroundInactive from '../assets/images/fr-hero-tab-deselected.gif';
import TabHoverInactive from '../assets/images/fr-hero-tab-deselected_hover.gif';
import { media } from '../../../theme/media';
import { TYPES } from './donationBox.component';
import { focusOutline } from '../../../theme/mixins';
import TickIconDarkGrey from '../../../images/icon-tick-dark-grey.svg';
import TickIconRed from '../../../images/icon-tick-red.svg';
import TickIconBlue from '../../../images/icon-tick-blue.svg';

const OPACITY_MASK = 'linear-gradient(to top, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, .8) 100%)';
// Altering the opacity mask on Firefox to be 90% to allow a smooth transition
const MOZ_OPACITY_MASK = 'linear-gradient(to top, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, .8) 100%)';

export const DONATION_THEME_COLOR = {
  [BUTTON_COLOR_LABEL.YELLOW]: COLORS.YELLOW,
  [BUTTON_COLOR_LABEL.BLUE]: COLORS.BLUE_ACCESSIBLE,
  [BUTTON_COLOR_LABEL.RED]: COLORS.MEDIUM_RED,
};

export const TICK_BY_COLOR_THEME = {
  [BUTTON_COLOR_LABEL.YELLOW]: TickIconDarkGrey,
  [BUTTON_COLOR_LABEL.BLUE]: TickIconBlue,
  [BUTTON_COLOR_LABEL.RED]: TickIconRed,
};

// Converts theme color to proper 'color' prop for Button
export const BUTTON_COLOR_DONATION = {
  [BUTTON_COLOR_LABEL.YELLOW]: [BUTTON_COLOR_LABEL.YELLOW],
  [BUTTON_COLOR_LABEL.BLUE]: [BUTTON_COLOR_LABEL.BLUE],
  [BUTTON_COLOR_LABEL.RED]: [BUTTON_COLOR_LABEL.MEDIUM_RED],
};

// Font color for tab labels
const TAB_COLOR_BY_THEME = {
  [BUTTON_COLOR_LABEL.YELLOW]: COLORS.DARK_GRAY,
  [BUTTON_COLOR_LABEL.BLUE]: COLORS.BLUE_ACCESSIBLE,
  [BUTTON_COLOR_LABEL.RED]: COLORS.RED,
};

// theme-color: the general theme color to be used on button and link accents
// theme-font-color: either dark-gray or white, the font to be used over the theme color background
// theme-tab-color: the text color to be used for the tab 'Monthly' or 'Give Once' labels
export const BoxContainer = styled(Section)`
  --theme-color: ${props => props.theme ? DONATION_THEME_COLOR[props.theme] : COLORS.BLUE_ACCESSIBLE};
  --theme-font-color: ${props => props.themeBase ? TEXT_COLOR[props.themeBase] : COLORS.WHITE};
  --theme-tab-color: ${props => props.theme ? TAB_COLOR_BY_THEME[props.themeBase] : COLORS.DARK_GRAY};
  --tab-back-active: url(${TabBackgroundActive});
  --tab-back-inactive: url(${TabBackgroundInactive});
  --tab-hover-inactive: url(${TabHoverInactive});

  margin-top: ${props => props.type === TYPES.TABS ? '0' : '92%'};
  margin-bottom: -1px;
  mask-image: ${props => props.type === TYPES.TABS ? 'none' : OPACITY_MASK};

  ${media.tablet`
    mask-image: none;
    margin-top: 0px;
    background-color: ${props => props.type === TYPES.TABS ? 'transparent' : '#FFFFFFE6'};
  `}

  @-moz-document url-prefix() {
    mask-image: ${props => props.type === TYPES.TABS ? 'none' : MOZ_OPACITY_MASK};
  }
`;

export const DonationContainer = styled.div`
  background-color: ${COLORS.WHITE};
  padding: ${props => props.type === TYPES.TABS ? '30px 30px 36px' : '27px 30px'};
  margin-bottom: -1px;
  mask-image: ${props => props.type === TYPES.TABS ? 'none' : OPACITY_MASK};
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.type === TYPES.TABS ? '25px' : '26px'};

  ${media.tablet`
    padding: 35px 35px 40px;
  `}

  @-moz-document url-prefix() {
    mask-image: ${props => props.type === TYPES.TABS ? 'none' : MOZ_OPACITY_MASK};
  }
`;

export const TabContainer = styled.div`
  display: inline-flex;
  gap: 3px;
  width: 100%;
`;

const TabBase = styled.button`
  font-family: ${FONTS.TITLE_FONT};
  font-size: .938rem;
  font-weight: 700;
  border: none;
  color: ${props => props.active ? 'var(--theme-tab-color)' : COLORS.MEDIUM_LIGHT_GRAY};
  justify-content: center;
  align-items: center;
  display: inline-flex;
  pointer-events: auto;
  cursor: pointer;
  width: 50%;
  height: 46px;
  background-image: ${props => props.active ? 'var(--tab-back-active)' : 'var(--tab-back-inactive)'};
  background-size: contain;
  box-shadow: 0 2px 0 0 ${COLORS.WHITE};
  
  img {
    height: .8rem;
    margin: 0 5px 3px 0;
  }

  &:hover {
    color: ${props => props.active ? 'var(--theme-tab-color)' : COLORS.MEDIUM_DARKER_GRAY};
    background-image: ${props => props.active ? 'var(--tab-back-active)' : 'var(--tab-hover-inactive)'};
  }
`;

export const GiveOnceTab = styled(TabBase)`
`;

export const MonthlyTab = styled(TabBase)`
`;

export const TabsDescription = styled.div`
  font-size: .85rem;
  font-style: italic;
  font-weight: 400;
  font-family: ${FONTS.SECOND_FONT};
  color: ${COLORS.DARK_GRAY};
  margin-block: -4px 3px;

  ${media.tablet`
    margin: 0;
  `}
`;

// Checkbox
export const CheckboxContainer = styled.div`
  padding: 7px 0 26px 0;

  ${media.tablet`
    padding: 9px 0 24px 0;
  `}

`;

export const Label = styled.label`
  position: absolute;
  padding-left: 28px;
  cursor: pointer;
  font-family: ${FONTS.SMALL_FONT};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
  outline: none;

  &::selection {
    background-color: transparent;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 20px;
    height: 20px;
    border: 1px solid ${COLORS.DARK_GRAY};
    background-color: transparent;
    transition: background-color .2s, border-color .2s;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(${TickIconSrc});
    background-repeat: no-repeat;
    top: 4px;
    left: 5px;
    width: 10px;
    height: 8px;
    transition: opacity .2s;
  }

  ${media.tablet`
    font-size: 0.875rem;
    line-height: 1;
  `}
`;

export const Checkbox = styled.input`
  position: absolute;
  padding-left: 32px;
  opacity: 0;

  & + ${Label}:before {
    border-color: #B4B4B5;
    background-color: ${COLORS.WHITE};
  }

  &:not(:checked) + ${Label}:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + ${Label}:after {
    opacity: 1;
    transform: scale(1.5);
  }

  &:focus-visible + ${Label} {
    ${focusOutline}
  }
`;

export const Button = styled(ButtonComponent)`
  font-size: 1.25rem;
  
  img {
    width: 13px;
    margin: 0 0.5em 0.2em 0;
  }
`;
