import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from '../link/link.component';
import ColorChangeableDivider from '../colorChangeableDivider/colorChangeableDivider.component';
import * as Styled from './missionBreakout.styles';
import { Markdown } from '../markdown';
import BgVideo from './bgVideo.component';
import MendedHeart from '../assets/images/mendedHeartSvg';
import { COLOR_CHANGEABLE_DIVIDER_COLOR_LABEL, COLORS } from '../styles/homePageStyles';
import { PlatformDetector } from '../../platformDetector/platformDetector.component';
import { AnchoredScrollText } from '../anchoredScrollText';
import { getIsMobileTypeDevice } from '../../../layouts/homePage/homePage.component';
import { CurtainConsumer } from '../../curtain/CurtainProvider.container';

const MissionBreakout = ({
  bgMovieMobile,
  bgMovieDesktop,
  bgImageFallback,
  headlineComplete,
  text,
  link,
  showAnchoredScrollText,
}) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [bgMovie, setBgMovie] = useState(bgMovieMobile);
  const isMobileTypeDevice = getIsMobileTypeDevice(navigator, window);

  const setSize = () => {
    const isMobileScreen = window.innerWidth < 550;

    if (isMobileScreen) {
      setBgMovie(bgMovieMobile);
    } else {
      setBgMovie(bgMovieDesktop);
    }
  };

  useEffect(() => {
    setSize();

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setSize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Fragment>
      <CurtainConsumer>
        {curtain => {
          return (
            <PlatformDetector>
              {({ isMobile, isTabletPortrait, isTabletLandscape }) =>
                curtain ||
                isMobile ||
                isTabletPortrait ||
                !showAnchoredScrollText ||
                isMobileTypeDevice ? (
                    <Fragment>
                      <Styled.MissionBreakoutWrapper windowHeight={windowHeight}>
                        {bgMovie ? (
                          <Styled.VideoEmbed id="video-embed">
                            <BgVideo poster={bgImageFallback} directUrl={bgMovie} />
                          </Styled.VideoEmbed>
                        ) : (
                          <img src={bgImageFallback} alt="Background" />
                        )}
                        <Styled.TextContainer>
                          {isTabletLandscape ? (
                            <Styled.Header>
                            Because
                              <span>
                                <MendedHeart colorHex={COLORS.BRIGHT_RED} heightAndWidth={30} />
                              </span>
                              <em>{headlineComplete.toLowerCase()}.</em>
                            </Styled.Header>
                          ) : (
                            <Styled.Header>
                              <em>Because</em>
                              <span>
                                <MendedHeart colorHex={COLORS.BRIGHT_RED} heightAndWidth={30} />
                              </span>
                              {headlineComplete}.
                            </Styled.Header>
                          )}

                          <Styled.Text>
                            <Markdown>{text}</Markdown>
                          </Styled.Text>
                          <Link url={link.href} text={link.text} />
                        </Styled.TextContainer>
                      </Styled.MissionBreakoutWrapper>
                      <ColorChangeableDivider color={COLOR_CHANGEABLE_DIVIDER_COLOR_LABEL.GREEN} />
                    </Fragment>
                  ) : (
                    <AnchoredScrollText />
                  )
              }
            </PlatformDetector>
          );
        }}
      </CurtainConsumer>
    </Fragment>
  );
};

MissionBreakout.propTypes = {
  bgMovieMobile: PropTypes.string,
  bgMovieDesktop: PropTypes.string,
  showAnchoredScrollText: PropTypes.bool.isRequired,
  bgImageFallback: PropTypes.string.isRequired,
  headlineComplete: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

MissionBreakout.defaultProps = {
  headlineComplete: 'animals matter',
};

export default MissionBreakout;
