import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const CurtainContext = createContext(false);

export const CurtainProvider = ({ value, children }) => {
  return <CurtainContext.Provider value={value}>{children}</CurtainContext.Provider>;
};

CurtainProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.bool,
};

export const CurtainConsumer = CurtainContext.Consumer;
export default CurtainProvider;
