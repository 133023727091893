import styled, { css } from 'styled-components';

// Fonts
// Montserrat
import 'fontsource-montserrat/200-normal.css';
import 'fontsource-montserrat/300-normal.css';
import 'fontsource-montserrat/400-normal.css';
import 'fontsource-montserrat/700-normal.css';
import 'fontsource-montserrat/600-normal.css';
import 'fontsource-montserrat/500-normal.css';

// Merriweather
import '@fontsource/merriweather/300.css';
import '@fontsource/merriweather/300-italic.css';
import '@fontsource/merriweather/700.css';
import '@fontsource/merriweather/400-italic.css';
import '@fontsource/merriweather/700-italic.css';

// Open Sans
import 'fontsource-open-sans/300.css';
import 'fontsource-open-sans/400.css';
import 'fontsource-open-sans/600.css';
import 'fontsource-open-sans/700.css';
// Noto Serif
import 'fontsource-noto-serif/400.css';
import 'fontsource-noto-serif/400-italic.css';
import 'fontsource-noto-serif/700.css';
import { media } from '../../../theme/media';
import { text } from '../../../theme/typography';

export const OUTER_GRID_WIDTH = '1380px';
export const INNER_GRID_WIDTH = '1190px';
export const PADDING_HORIZONTAL_LARGE = '85px';
export const PADDING_HORIZONTAL_SMALL = '24px';

export const DIVIDER_COLOR = '#ebebec';
export const DIVIDER_WIDTH = '1px';

export const GUTTER_RESPONSIVE = `clamp(${PADDING_HORIZONTAL_SMALL}, -0.0032px + 6.16vw, ${PADDING_HORIZONTAL_LARGE})`;

export const MASK_IMAGE_NOTCH = 'linear-gradient(45deg, black 93%, transparent 7%)';

export const FADE_IN_UP_DISTANCE = '10px';

export const RESPONSIVE_BOTTOM_MARGIN = 'clamp(100px, 10vw, 150px)';

export const FONTS = {
  TITLE_FONT: 'Montserrat, sans-serif',
  MAIN_FONT: 'Merriweather, serif',
  SECOND_FONT: 'Noto Serif, serif',
  SMALL_FONT: 'Open Sans, sans-serif',
};

// Colors
export const COLORS = {
  LIGHT_GREEN: '#C2D500',
  LIGHTEST_GRAY: '#F2F2F2',
  LIGHT_GRAY: '#E6E6E8',
  MEDIUM_LIGHT_GRAY: '#666666',
  MEDIUM_GRAY: '#626569',
  MEDIUM_DARKER_GRAY: '#404040',
  MEDIUM_DARK_GRAY: '#28333D',
  DARK_GRAY: '#1D252C',
  NEUTRAL_GRAY: '#D5D6D8',
  BRIGHT_RED: '#FE3B1F',
  MEDIUM_RED: '#EF4129',
  RED: '#D61E25',
  TEAL: '#009579',
  YELLOW: '#FFB601',
  BLUE: '#00ABC8',
  BLUE_ACCESSIBLE: '#008299',
  WHITE: '#FFFFFF',
  BLACK: '#28333D',
};

export const COLOR_CHANGEABLE_DIVIDER_COLOR_LABEL = {
  GREEN: 'green',
  BLUE: 'blue',
};

export const BUTTON_COLOR_LABEL = {
  BLUE: 'blue',
  GREEN: 'green',
  MEDIUM_RED: 'medium_red',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
};

export const BUTTON_COLOR = {
  [BUTTON_COLOR_LABEL.BLUE]: COLORS.BLUE_ACCESSIBLE,
  [BUTTON_COLOR_LABEL.GREEN]: COLORS.LIGHT_GREEN,
  [BUTTON_COLOR_LABEL.MEDIUM_RED]: COLORS.MEDIUM_RED,
  [BUTTON_COLOR_LABEL.RED]: COLORS.RED,
  [BUTTON_COLOR_LABEL.TEAL]: COLORS.TEAL,
  [BUTTON_COLOR_LABEL.YELLOW]: COLORS.YELLOW,
};

export const TEXT_COLOR = {
  [BUTTON_COLOR_LABEL.BLUE]: COLORS.WHITE,
  [BUTTON_COLOR_LABEL.GREEN]: COLORS.DARK_GRAY,
  [BUTTON_COLOR_LABEL.MEDIUM_RED]: COLORS.WHITE,
  [BUTTON_COLOR_LABEL.RED]: COLORS.WHITE,
  [BUTTON_COLOR_LABEL.TEAL]: COLORS.WHITE,
  [BUTTON_COLOR_LABEL.YELLOW]: COLORS.DARK_GRAY,
};

export const formatColorLabels = colorLabels => {
  const formattedColorLabels = {};
  Object.entries(colorLabels).forEach(([label, color]) => {
    const titleCaseLabel = label
      .toLocaleLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    formattedColorLabels[titleCaseLabel] = color;
  });
  return formattedColorLabels;
};

export const fadeInUp = (isOnScreen, duration) => css`
  transform: ${isOnScreen ? 'none' : `translateY(${FADE_IN_UP_DISTANCE})`};
  opacity: ${isOnScreen ? '1' : '0'};
  transition-property: transform, opacity;
  transition-duration: ${duration || 0.5}s;
  transition-timing-function: ease-out;
`;

export const Section = styled.section`
  ${text};
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const OuterContainer = styled(Container)`
  max-width: ${OUTER_GRID_WIDTH};
`;

export const InnerContainer = styled(Container)`
  max-width: ${INNER_GRID_WIDTH};
`;

export const Pad = styled.div`
  padding-inline: ${GUTTER_RESPONSIVE};
`;

export const H2 = styled.h2`
  font-family: ${FONTS.TITLE_FONT};
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;

  em {
    font-family: ${FONTS.SECOND_FONT};
    font-weight: normal;
    text-transform: none;
  }
`;

export const H3 = styled.h3`
  font-family: ${FONTS.TITLE_FONT};
  font-size: 1.25rem;
  line-height: 1.25;
  text-transform: uppercase;

  ${media.mobile`
    font-size: 1.5rem;
    line-height: 1.3;
  `};
`;

export const SmallText = styled.span`
  font-family: ${FONTS.SMALL_FONT};
  font-weight: 400;
  font-size: 0.875rem;

  ${media.tablet`
    font-size: 0.9rem;
  `};
`;

export const ImageCredit = styled.div`
  position: absolute;
  font-size: 0.625rem;
  line-height: 1;
  font-family: ${FONTS.SMALL_FONT};
  color: ${COLORS.LIGHT_GRAY};
  white-space: nowrap;
  right: 0.5em;

  ${media.tablet`
    bottom: 1em;
    top: auto;
    margin: 0;
    transform-origin: bottom right;
    transform: rotate(-90deg) translateX(100%);
  `}

  top: 6em;
  transform-origin: top right;
  transform: rotate(-90deg) translateY(-100%);
`;
